import { jsx as _jsx } from "react/jsx-runtime";
import "./BarChart.scss";
import { useQuery } from "@tanstack/react-query";
import { useState, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { Home, ZoomArea, Move, CirclePlus, CircleMinus, SquareArrowInRightOutlined } from "@avinor/react-icons";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import { AvinorColor } from "@constants";
import { Delay, FormatPattern } from "@enums";
import { useDeIceHandlerContext } from "@features/DeIce/contexts/DeIceHandlerContext";
import { useDeIceDateSelector } from "@features/DeIce/hooks/useDeIceDateSelector";
import { getDeIcePlan, getPadConfiguration } from "@features/DeIce/services";
import { filterABPlaneBodySizesWithoutRollover, filterCPlaneBodySize, filterDEFPlaneBodySizesWithWidebodyWithRollover, filterDEFPlaneBodySizesWithWidebodyWithoutRollover, filterUnknownPlaneBodySize, getHandlerFlightCapacities, groupPlaneBodySize, } from "@features/DeIce/utils";
import { defaultApexChartOptions } from "@charts/apexcharts/defaultApexChartOptions";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import CustomTooltip from "./CustomTooltip";
const excludeEmptyFlights = (segments) => {
    const firstIndex = segments.findIndex((segment) => segment.flightMovementsForSegment !== 0);
    const lastIndex = segments.length - 1 - [...segments].reverse().findIndex((segment) => segment.flightMovementsForSegment !== 0);
    if (firstIndex === -1)
        return segments;
    const adjustedLastIndex = Math.max(lastIndex, firstIndex);
    return segments.slice(firstIndex, adjustedLastIndex + 1);
};
const BarChart = () => {
    const isMobile = true;
    const { utc } = useUtcContext();
    const { selectedDeIceHandlers } = useDeIceHandlerContext();
    const deIceDataDate = useDeIceDateSelector();
    const { iata } = useIataContext();
    const { t } = useTranslation();
    const { data: deIceConfiguration, isLoading: isLoadingPadConfig, isSuccess: isPadConfigSuccess, } = useQuery({
        queryFn: () => getPadConfiguration({
            date: deIceDataDate,
            iata,
        }),
        refetchInterval: 0,
        staleTime: 0,
        refetchOnWindowFocus: false,
        gcTime: 0,
        queryKey: ["getPadConfiguration", { deIceDataDate, iata }],
    });
    const { data: deicePlan, isLoading } = useQuery({
        queryFn: () => {
            const availableHandlers = [
                ...new Set(deIceConfiguration === null || deIceConfiguration === void 0 ? void 0 : deIceConfiguration.pads.filter((x) => x.active).flatMap((x) => x.handlers)),
            ];
            if (availableHandlers.length === 0 || selectedDeIceHandlers.some((x) => x === "NONE")) {
                return null;
            }
            if (selectedDeIceHandlers.length == 0) {
                return getDeIcePlan({
                    params: {
                        date: deIceDataDate,
                        iata,
                        SGH: availableHandlers.some((x) => x === "SGH"),
                        WGH: availableHandlers.some((x) => x === "WGH"),
                        Menzies: availableHandlers.some((x) => x === "Menzies"),
                    },
                });
            }
            else {
                return getDeIcePlan({
                    params: {
                        date: deIceDataDate,
                        iata,
                        SGH: selectedDeIceHandlers.some((x) => x === "SGH"),
                        WGH: selectedDeIceHandlers.some((x) => x === "WGH"),
                        Menzies: selectedDeIceHandlers.some((x) => x === "Menzies"),
                    },
                });
            }
        },
        staleTime: Delay.FiveMinutes,
        queryKey: ["getDeIcePlan", { deIceDataDate, iata, selectedDeIceHandlers, deIceConfiguration }],
        enabled: isPadConfigSuccess, // Enable this query only when the pad configuration query is successful
    });
    const [chartAnnotationPosition, setChartAnnotationPosition] = useState([true, true, true, true, true]);
    const timeSegments = useMemo(() => {
        if (!deicePlan)
            return [];
        return excludeEmptyFlights(deicePlan.timeSegments);
    }, [deicePlan]);
    const { abPlaneBodySizes, cPlaneBodySizes, dfPlaneBodySizes, extraTime, planeBodySizeUnknown } = useMemo(() => groupPlaneBodySize(timeSegments), [timeSegments]);
    const xaxisCategories = useMemo(() => timeSegments.map((segment) => segment.to), [timeSegments]);
    const series = useMemo(() => [
        { name: "A-B", type: "column", data: abPlaneBodySizes },
        { name: "C", type: "column", data: cPlaneBodySizes },
        { name: "D-F", type: "column", data: dfPlaneBodySizes },
        { name: t("charts.deice.bar.capacity.legends.extraTime"), type: "column", data: extraTime },
        { name: "Ukjent", type: "column", data: planeBodySizeUnknown },
        {
            name: t("charts.deice.bar.capacity.legends.capacity"),
            type: "line",
            data: getHandlerFlightCapacities(timeSegments, deicePlan !== null && deicePlan !== void 0 ? deicePlan : {}),
        },
    ], [
        abPlaneBodySizes,
        cPlaneBodySizes,
        dfPlaneBodySizes,
        extraTime,
        planeBodySizeUnknown,
        t,
        timeSegments,
        deicePlan,
    ]);
    const sharedStyles = useMemo(() => ({
        colors: AvinorColor.Grey900,
        fontSize: isMobile ? "0.75rem" : "0.875rem",
        fontWeight: "normal",
    }), [isMobile]);
    const options = useMemo(() => ({
        colors: [
            AvinorColor.MintGreen200,
            AvinorColor.VioletDark500,
            AvinorColor.VioletPrimaryLight,
            AvinorColor.VioletDark200,
            AvinorColor.Grey600,
            AvinorColor.BlueAttentionPrimary,
        ],
        chart: {
            offsetY: isMobile ? 0 : 20,
            toolbar: {
                tools: {
                    download: ReactDOMServer.renderToStaticMarkup(_jsx(SquareArrowInRightOutlined, { style: { transform: "rotate(90deg) scale(0.75)" } })),
                    zoom: ReactDOMServer.renderToStaticMarkup(_jsx(ZoomArea, {})),
                    zoomin: ReactDOMServer.renderToStaticMarkup(_jsx(CirclePlus, {})),
                    zoomout: ReactDOMServer.renderToStaticMarkup(_jsx(CircleMinus, {})),
                    pan: ReactDOMServer.renderToStaticMarkup(_jsx(Move, {})),
                    reset: ReactDOMServer.renderToStaticMarkup(_jsx(Home, {})),
                },
                offsetY: 0,
            },
            fontFamily: "Roboto, sans-serif",
            type: "line",
            stacked: true,
            zoom: { type: "x" },
            offsetX: 5,
            events: {
                legendClick: function (_, seriesIndex) {
                    setChartAnnotationPosition((prev) => prev.map((visible, index) => (index === seriesIndex ? !visible : visible)));
                },
            },
        },
        stroke: {
            width: [0, 0, 0, 0, 0, 2],
            curve: "stepline",
        },
        grid: {
            show: true,
            strokeDashArray: 4,
            borderColor: AvinorColor.Grey200,
            xaxis: { lines: { show: true } },
        },
        annotations: {
            points: [
                ...timeSegments
                    .filter(({ flights }) => flights && flights.length > 0)
                    .map(({ to, flights }) => {
                    const calculateTotalFlightsBasedOnLegendSelection = () => {
                        const totalFlights = (chartAnnotationPosition[0]
                            ? flights.filter(filterABPlaneBodySizesWithoutRollover).length
                            : 0) +
                            (chartAnnotationPosition[1] ? flights.filter(filterCPlaneBodySize).length : 0) +
                            (chartAnnotationPosition[2]
                                ? flights.filter(filterDEFPlaneBodySizesWithWidebodyWithoutRollover).length
                                : 0) +
                            (chartAnnotationPosition[3]
                                ? flights.filter(filterDEFPlaneBodySizesWithWidebodyWithRollover).length
                                : 0) +
                            (chartAnnotationPosition[4]
                                ? flights.filter(filterUnknownPlaneBodySize).length
                                : 0);
                        return totalFlights;
                    };
                    const totalFlights = calculateTotalFlightsBasedOnLegendSelection();
                    if (totalFlights === 0)
                        return {};
                    return {
                        x: new Date(to).getTime(),
                        y: totalFlights,
                        label: {
                            borderWidth: 0,
                            style: { fontSize: "0.75rem", background: "transparent", border: "none" },
                            orientation: "horizontal",
                            text: `${totalFlights}`,
                            offsetY: -5,
                        },
                        marker: { fillColor: "transparent", strokeColor: "transparent" },
                    };
                }),
            ],
            xaxis: defaultApexChartOptions.annotations.xaxis,
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1, 2, 3, 4],
            style: { colors: [AvinorColor.Grey700, AvinorColor.White, AvinorColor.White] },
        },
        xaxis: {
            type: "datetime",
            categories: xaxisCategories,
            title: { text: t("charts.deice.bar.capacity.xaxisTitle"), style: sharedStyles },
            labels: {
                style: sharedStyles,
                datetimeUTC: utc,
                rotate: -90,
                rotateAlways: true,
                format: FormatPattern.HH_mm,
            },
        },
        yaxis: {
            floating: false,
            min: 0,
            max: 15,
            tickAmount: 15,
            title: { text: t("charts.deice.bar.capacity.yaxisTitle"), style: sharedStyles },
            labels: {
                formatter: (value) => Math.round(value).toString(),
                style: sharedStyles,
                offsetX: 10,
            },
        },
        legend: defaultApexChartOptions.legend,
        tooltip: {
            fixed: { enabled: true, position: "topLeft" },
            custom: (options) => {
                const { dataPointIndex } = options;
                if (dataPointIndex === undefined)
                    return "";
                return ReactDOMServer.renderToStaticMarkup(_jsx(CustomTooltip, { dataPointIndex: dataPointIndex, timeSegments: timeSegments, utc: utc }));
            },
        },
        responsive: [
            {
                breakpoint: 768,
                options: {
                    chart: { toolbar: { offsetY: 0 } },
                    annotations: { points: undefined, xaxis: undefined },
                    xaxis: { title: { text: undefined } },
                    yaxis: {
                        floating: true,
                        min: 0,
                        max: 15,
                        tickAmount: 15,
                        title: { text: undefined },
                        labels: {
                            formatter: (value) => Math.round(value).toString(),
                            style: sharedStyles,
                        },
                    },
                    legend: {
                        position: "bottom",
                        offsetY: 25, // Adjust this value to move the legend further down
                    },
                },
            },
        ],
    }), [
        abPlaneBodySizes,
        cPlaneBodySizes,
        dfPlaneBodySizes,
        extraTime,
        isMobile,
        planeBodySizeUnknown,
        sharedStyles,
        t,
        timeSegments,
        utc,
        xaxisCategories,
    ]);
    if (isLoading || isLoadingPadConfig)
        return (_jsx("div", { style: { minHeight: "500px" }, children: _jsx(Spinner, {}) }));
    if (!deicePlan || (deicePlan === null || deicePlan === void 0 ? void 0 : deicePlan.timeSegments.length) === 0 || !(deIceConfiguration === null || deIceConfiguration === void 0 ? void 0 : deIceConfiguration.pads.some((x) => x.active)))
        return _jsx(NoDataAvailable, { minHeight: 500 });
    return (_jsx("div", { className: "deice-capacity-bar-chart", children: _jsx(ReactApexChart, { options: options, series: series, type: "bar", height: 500 }) }));
};
export default BarChart;
